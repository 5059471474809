<template>
    <b-container fluid>
        <b-row align-v="stretch" class="sba-white-card-lg-up">
            <b-col
                cols="12"
                lg="8"
                class="sba-white-card-lg-down p-4 my-3 my-lg-0"
            >
                <advanced-filter
                    :filter-choices="allData.filterChoices"
                    :initiatives="filteredInitiatives"
                />
            </b-col>
            <b-col
                cols="12"
                lg="4"
                class="sba-white-card-lg-down p-4 mt-3 mt-lg-0"
            >
                <b-form-input
                    v-model="searchText"
                    placeholder="Search for a product class or select from the list below"
                    class="filter-search"
                />
                <div class="my-2 d-flex align-items-center">
                    <span>Selected:</span>
                    <b-button
                        v-if="selectedProductClass.name.length"
                        pill
                        class="badge d-flex align-items-center"
                        @click="clearFilter"
                    >
                        <i style="white-space:normal;">
                            {{ selectedProductClass.name }}
                        </i>
                        <span class="ms-1">x</span>
                    </b-button>
                    <span v-else class="small" style="margin-left:30px">
                        <i>No product class selected</i>
                    </span>
                </div>
                <div class="overflow-auto mt-2" style="max-height: 400px">
                    <b-list-group
                        v-if="filteredProductClasses.length"
                        class="filtered-product-class-list"
                    >
                        <b-list-group-item
                            v-for="productClass in filteredProductClasses"
                            :key="productClass.name"
                            :active="
                                productClass.id === selectedProductClass.id
                            "
                            @click="toggleSelected(productClass)"
                        >
                            {{ productClass.name }}
                        </b-list-group-item>
                    </b-list-group>
                    <b-alert v-else show variant="warning" class="mt-2">
                        No results available.
                    </b-alert>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import _isEmpty from 'lodash/isEmpty';
import AdvancedFilter from './AdvancedFilter.vue';

const PRODUCT_CLASS_FILTER_STORAGE_KEY = 'selectedProductClass';

export default Vue.component('HomeProductClasses', {
    components: {
        AdvancedFilter,
    },
    props: {},
    data() {
        return {
            searchText: '',
            selectedProductClass: {
                name: '',
                id: '',
            },
        };
    },
    computed: {
        filteredProductClasses() {
            if (this.searchText) {
                return this.allData.productClasses.filter(productClass =>
                    new RegExp(this.searchText, 'i').test(productClass.name),
                );
            }
            return this.allData.productClasses;
        },
        filteredInitiatives() {
            if (this.selectedProductClass.id) {
                return this.allData.initiatives.filter(
                    initiative =>
                        initiative.product_or_material_class_id ===
                        this.selectedProductClass.id,
                );
            }
            return this.allData.initiatives;
        },
    },
    watch: {
        selectedProductClass: {
            handler(val) {
                localStorage.setItem(
                    PRODUCT_CLASS_FILTER_STORAGE_KEY,
                    JSON.stringify(val),
                );
            },
        },
    },
    created() {
        this.allData = JSON.parse(
            document.getElementById('product-class-data').textContent,
        );
    },
    mounted() {
        // Get filters params from local storage.
        const selectedProductClass = JSON.parse(
            localStorage.getItem(PRODUCT_CLASS_FILTER_STORAGE_KEY),
        );
        if (!_isEmpty(selectedProductClass)) {
            this.selectedProductClass = selectedProductClass;
        }
    },
    methods: {
        toggleSelected(productClass) {
            if (this.selectedProductClass === productClass) {
                this.clearFilter();
            } else {
                this.selectedProductClass = productClass;
            }
        },
        clearFilter() {
            this.selectedProductClass = {
                name: '',
                id: '',
            };
        },
    },
});
</script>
