<template>
    <div>
        <b-row class="my-3">
            <b-col cols="12">
                <p v-if="hasValueAndIsNotNA(apd.KPIDesc)" class="my-0 small">
                    {{ apd.KPIDesc }}
                </p>
            </b-col>
        </b-row>
        <b-row
            v-if="
                apd.value || hasValueAndIsNotNA(apd.quantitativeDataQualifier)
            "
            class="my-3"
        >
            <b-col cols="12">
                <div class="p-3 sba-rounded-card">
                    <h5>Quantitative Data</h5>
                    <p>
                        <span>{{ apd.value }} {{ apd.unit }}</span>
                    </p>
                    <p v-if="hasValueAndIsNotNA(apd.quantitativeDataQualifier)">
                        {{ apd.quantitativeDataQualifier }}
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row
            v-if="
                shouldShowAdditionalInfoCard(
                    apd.qualitativeData,
                    apd.cumulativeData,
                )
            "
        >
            <b-col cols="12">
                <div class="p-3 sba-rounded-card">
                    <h5>Additional Information</h5>
                    <p v-if="apd.qualitativeData">
                        {{ apd.qualitativeData }}
                    </p>
                    <p v-if="apd.cumulativeData">
                        {{ apd.cumulativeData }}
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row class="my-1">
            <b-col cols="12">
                <strong class="small">
                    Source:
                    <a :href="apd.dataSource" target="_blank">
                        {{ apd.dataSourceType }}
                    </a>
                </strong>
                <p v-if="apd.dataSourceInfo" class="px-5">
                    <i>{{ apd.dataSourceInfo }}</i>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { shape, integer, string } from 'vue-types';
import VueTypes from 'vue-types';

const nullableString = VueTypes.oneOfType([string, VueTypes.null]);
const nullableInteger = VueTypes.oneOfType([integer, VueTypes.null]);

export default Vue.component('ApdReport', {
    props: {
        apd: shape({
            value: nullableInteger,
            year: nullableInteger,
            quantitativeDataQualifier: nullableString,
            qualitativeData: nullableString,
            cumulativeData: nullableString,
            linkedKPI: string(),
            KPIDesc: string(),
            unit: nullableString,
            dataSourceType: string(),
            dataSource: nullableString,
            dataSourceInfo: nullableString,
        }),
    },
    methods: {
        hasValueAndIsNotNA(text) {
            return Boolean(text.length && text.toLowerCase() !== 'n/a');
        },
        shouldShowAdditionalInfoCard(qualitativeData, cumulativeData) {
            return Boolean(qualitativeData || cumulativeData);
        },
    },
});
</script>
