<template>
    <div>
        <b-list-group
            v-if="sortedInitatives.length"
            class="overflow-auto mt-1 pe-2"
            style="height: 400px"
        >
            <b-list-group-item
                v-for="initiative in sortedInitatives"
                :key="initiative.initiative_name"
                :href="'/initiatives/' + initiative.id"
                class="initiative-search-results"
            >
                <b-row align-v="center">
                    <b-col cols="12" md="9" lg="8" xl="9">
                        <b-row align-v="center">
                            <b-col
                                order="1"
                                order-md="2"
                                cols="10"
                                lg="9"
                                xl="10"
                            >
                                <div class="py-2">
                                    <h6>
                                        {{ initiative.initiative_name }}
                                    </h6>

                                    <div class="small fw-normal">
                                        {{ initiative.organisation__name }}
                                    </div>
                                </div>
                            </b-col>
                            <b-col
                                order="2"
                                order-md="1"
                                cols="2"
                                lg="3"
                                xl="2"
                            >
                                <initiative-accredited-symbology
                                    v-if="
                                        initiative.accreditation_status ==
                                            'accredited'
                                    "
                                    image-type="small"
                                    show-tooltip
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="3" lg="4" xl="3" class="pt-3 pt-md-0">
                        <div class="d-flex align-items-center">
                            <initiative-symbology
                                :symbol-type="initiative.geographic_scope"
                                show-tooltip
                            />
                            <initiative-symbology
                                :symbol-type="initiative.type_of_initiative"
                                show-tooltip
                            />
                            <initiative-symbology
                                :symbol-type="initiative.development_stage"
                                show-tooltip
                            />
                            <lifecycle-summary-symbology
                                :production="initiative.lifecycle_is_production"
                                :consumption="
                                    initiative.lifecycle_is_consumption
                                "
                                :postconsumption="
                                    initiative.lifecycle_is_postconsumption
                                "
                                show-tooltip
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>
        <b-alert v-else show variant="warning" style="margin-top:6px">
            No results available.
        </b-alert>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { arrayOf, shape, integer, string, bool } from 'vue-types';
import InitiativeSymbology from './InitiativeSymbology.vue';
import LifecycleSummarySymbology from './LifecycleSummarySymbology.vue';

export default Vue.component('InitiativeResultsDisplay', {
    components: {
        InitiativeSymbology,
        LifecycleSummarySymbology,
    },
    props: {
        initiatives: arrayOf(
            shape({
                /* eslint-disable camelcase */
                last_updated: string(),
                initiative_name: string(),
                initiative_objective: string(),
                product_or_material_class_id: integer(),
                product_or_material_types_list: arrayOf(string()),
                id: integer(),
                geographic_scope: string(),
                type_of_initiative: string(),
                accreditation_status: string(),
                development_stage: string(),
                lifecycle_is_production: bool(),
                lifecycle_is_consumption: bool(),
                lifecycle_is_postconsumption: bool(),
                organisation__name: string(),
                minister_list: string(),
                regulatory_model: string(),
                /* eslint-enable camelcase */
            }),
        ),
        sortBy: string(),
    },
    computed: {
        sortedInitatives() {
            if (this.sortBy == 'sortDesc') {
                return this.initiatives.slice().reverse();
            } else {
                return this.initiatives;
            }
        },
    },
    methods: {
        applicableLifecycles: function(initiative) {
            let applicableLifecycles = [];
            if (initiative.lifecycle_is_production) {
                applicableLifecycles.push('production');
            }
            if (initiative.lifecycle_is_consumption) {
                applicableLifecycles.push('consumption');
            }
            if (initiative.lifecycle_is_postconsumption) {
                applicableLifecycles.push('postconsumption');
            }
            return 'lifecycle_is_' + applicableLifecycles.join('_');
        },
    },
});
</script>
