<template>
    <div>
        <span class="scroll-link d-flex align-items-center" @click="scroll">
            <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                style="width: 2em; height: 2em;"
            />
            <span class="px-2">Annual Performance Data</span>
        </span>
    </div>
</template>
<script>
import Vue from 'vue/dist/vue.esm.js';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faCircleChevronDown);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export default Vue.component('ScrollToApdSection', {
    methods: {
        scroll() {
            document
                .querySelector('#apd-header')
                .scrollIntoView({ behavior: 'smooth' });
        },
    },
});
</script>
