var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.relevantSymbol.fontAwesome
        ? _c(
            "span",
            {
              staticClass: "black me-2 symbology",
              attrs: {
                id: _vm.symbolId,
                alt: _vm.relevantSymbol.type + " " + _vm.relevantSymbol.desc
              }
            },
            [
              _c("font-awesome-icon", {
                staticStyle: { width: "2em", height: "2em" },
                attrs: { icon: "fa-solid " + _vm.relevantSymbol.name }
              })
            ],
            1
          )
        : _c("b-img", {
            staticClass: "me-2 symbology",
            staticStyle: { width: "2em", height: "2em" },
            attrs: {
              id: _vm.symbolId,
              src: _vm.baseSrc + _vm.relevantSymbol.src + ".png",
              alt: _vm.relevantSymbol.type + " " + _vm.relevantSymbol.desc
            }
          }),
      _vm._v(" "),
      _vm.showTooltip
        ? _c(
            "b-popover",
            {
              attrs: {
                target: _vm.symbolId,
                triggers: "hover",
                placement: "right",
                title: _vm.relevantSymbol.type
              }
            },
            _vm._l(_vm.splitDesc(_vm.relevantSymbol.desc), function(line) {
              return _c("p", { key: line, staticClass: "m-0" }, [
                _vm._v("\n            " + _vm._s(line) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showText
        ? _c(
            "div",
            _vm._l(_vm.splitDesc(_vm.relevantSymbol.desc), function(line) {
              return _c("p", { key: line, staticClass: "m-0" }, [
                _vm._v("\n            " + _vm._s(line) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }