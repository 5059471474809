<template>
    <div>
        <b-row v-if="filterChoices.length" align-v="stretch" align-h="between">
            <b-col cols="12" xl="auto" class="flex-grow-1">
                <b-form-input
                    v-model="searchText"
                    placeholder="Search by product, material, initiative or organisation name"
                    class="filter-search"
                />
            </b-col>
            <b-col cols="12" xl="auto" class="mt-2 mt-xl-0">
                <b-row>
                    <b-col class="d-flex">
                        <filter-dropdown
                            :filter-choices="filterChoices"
                            @update:filters="updateFilters"
                        />
                        <b-dropdown
                            dropdown
                            right
                            class="filter-button"
                            text="Sort by"
                        >
                            <b-list-group>
                                <label class="list-group-item d-flex gap-2">
                                    <input
                                        id="sortAsc"
                                        v-model="sortBy"
                                        value="sortAsc"
                                        class="form-check-input flex-shrink-0"
                                        type="radio"
                                    />
                                    <span>
                                        A
                                        <font-awesome-icon
                                            class="mx-1"
                                            icon="fa-solid fa-arrow-right-long"
                                        />
                                        Z
                                    </span>
                                </label>
                                <label class="list-group-item d-flex gap-2">
                                    <input
                                        id="sortDesc"
                                        v-model="sortBy"
                                        value="sortDesc"
                                        class="form-check-input flex-shrink-0"
                                        type="radio"
                                    />
                                    <span>
                                        Z
                                        <font-awesome-icon
                                            class="mx-1"
                                            icon="fa-solid fa-arrow-right-long"
                                        />
                                        A
                                    </span>
                                </label>
                            </b-list-group>
                        </b-dropdown>
                    </b-col>
                </b-row>
                <b-row class="mt-2 mb-1 px-3">
                    <div class="d-flex small disclaimer-card">
                        <i><strong>i</strong></i>
                        <span>&emsp;Hover over icons for more info</span>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <initiative-results-display
                :initiatives="filterAdvancedInitiatives"
                :sort-by="sortBy"
            />
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { arrayOf, shape, integer, string, bool } from 'vue-types';
import InitiativeResultsDisplay from './InitiativeResultsDisplay.vue';
import FilterDropdown from './FilterDropdown.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faArrowRightLong);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export default Vue.component('AdvancedFilter', {
    components: {
        InitiativeResultsDisplay,
        FilterDropdown,
    },
    props: {
        filterChoices: arrayOf(
            shape({
                name: string(),
                id: string(),
                attributes: {
                    labels: arrayOf(string()),
                    values: arrayOf(string()),
                },
            }),
        ),
        initiatives: arrayOf(
            shape({
                /* eslint-disable camelcase */
                last_updated: string(),
                initiative_name: string(),
                initiative_objective: string(),
                product_or_material_class_id: integer(),
                product_or_material_types_list: arrayOf(string()),
                id: integer(),
                geographic_scope: string(),
                type_of_initiative: string(),
                accreditation_status: string(),
                development_stage: string(),
                lifecycle_is_production: bool(),
                lifecycle_is_consumption: bool(),
                lifecycle_is_postconsumption: bool(),
                organisation__name: string(),
                minister_list: string(),
                regulatory_model: string(),
                /* eslint-enable camelcase */
            }),
        ),
    },
    data() {
        return {
            checked: {
                typeOfInitiative: [],
                developmentStage: [],
                lifecycleStage: [],
                accreditationStatus: [],
                geographicScope: [],
                ministerList: [],
                regulatoryModel: [],
            },
            sortBy: 'sortAsc',
            searchText: '',
        };
    },
    computed: {
        filterInitiatives() {
            if (this.searchText) {
                return this.initiatives.filter(initiative =>
                    new RegExp(this.searchText, 'i').test(
                        `${initiative.initiative_name}|${
                            initiative.organisation__name
                        }|${initiative.product_or_material_types_list.toString()}`,
                    ),
                );
            }
            return this.initiatives;
        },
        filterAdvancedInitiatives() {
            const shouldShow = (filterValues, value) => {
                if (filterValues.length) {
                    return filterValues.includes(value);
                }
                return true;
            };
            return this.filterInitiatives
                .filter(initiative =>
                    shouldShow(
                        this.checked.geographicScope,
                        initiative.geographic_scope,
                    ),
                )
                .filter(initiative =>
                    shouldShow(
                        this.checked.accreditationStatus,
                        initiative.accreditation_status,
                    ),
                )
                .filter(initiative =>
                    shouldShow(
                        this.checked.typeOfInitiative,
                        initiative.type_of_initiative,
                    ),
                )
                .filter(initiative =>
                    shouldShow(
                        this.checked.developmentStage,
                        initiative.development_stage,
                    ),
                )
                .filter(
                    initiative =>
                        this.checked.lifecycleStage.some(
                            lifecycle => initiative[lifecycle],
                        ) || !this.checked.lifecycleStage.length,
                )
                .filter(initiative =>
                    shouldShow(
                        this.checked.ministerList,
                        initiative.minister_list,
                    ),
                )
                .filter(initiative =>
                    shouldShow(
                        this.checked.regulatoryModel,
                        initiative.regulatory_model,
                    ),
                );
        },
    },
    methods: {
        updateFilters(filterType, newFilterValue) {
            this.checked[filterType] = newFilterValue;
        },
    },
});
</script>
