var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        {
          staticClass: "sba-white-card-lg-up",
          attrs: { "align-v": "stretch" }
        },
        [
          _c(
            "b-col",
            {
              staticClass: "sba-white-card-lg-down p-4 my-3 my-lg-0",
              attrs: { cols: "12", lg: "8" }
            },
            [
              _c("advanced-filter", {
                attrs: {
                  "filter-choices": _vm.allData.filterChoices,
                  initiatives: _vm.filteredInitiatives
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "sba-white-card-lg-down p-4 mt-3 mt-lg-0",
              attrs: { cols: "12", lg: "4" }
            },
            [
              _c("b-form-input", {
                staticClass: "filter-search",
                attrs: {
                  placeholder:
                    "Search for a product class or select from the list below"
                },
                model: {
                  value: _vm.searchText,
                  callback: function($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-2 d-flex align-items-center" },
                [
                  _c("span", [_vm._v("Selected:")]),
                  _vm._v(" "),
                  _vm.selectedProductClass.name.length
                    ? _c(
                        "b-button",
                        {
                          staticClass: "badge d-flex align-items-center",
                          attrs: { pill: "" },
                          on: { click: _vm.clearFilter }
                        },
                        [
                          _c(
                            "i",
                            { staticStyle: { "white-space": "normal" } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.selectedProductClass.name) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "ms-1" }, [_vm._v("x")])
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "small",
                          staticStyle: { "margin-left": "30px" }
                        },
                        [_c("i", [_vm._v("No product class selected")])]
                      )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "overflow-auto mt-2",
                  staticStyle: { "max-height": "400px" }
                },
                [
                  _vm.filteredProductClasses.length
                    ? _c(
                        "b-list-group",
                        { staticClass: "filtered-product-class-list" },
                        _vm._l(_vm.filteredProductClasses, function(
                          productClass
                        ) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: productClass.name,
                              attrs: {
                                active:
                                  productClass.id ===
                                  _vm.selectedProductClass.id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleSelected(productClass)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(productClass.name) +
                                  "\n                    "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _c(
                        "b-alert",
                        {
                          staticClass: "mt-2",
                          attrs: { show: "", variant: "warning" }
                        },
                        [
                          _vm._v(
                            "\n                    No results available.\n                "
                          )
                        ]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }