var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "sba-white-card mb-3" },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.displayText,
              expression: "displayText"
            }
          ],
          staticClass: "p-4 fs-3 text-start",
          attrs: { block: "" }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c("b", [_vm._v(_vm._s(_vm.displayText))]),
              _vm._v(" "),
              _c("b-badge", { attrs: { pill: "" } }, [
                _vm.orderedKPIs.length
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.orderedKPIs.length) +
                          "\n                "
                      )
                    ])
                  : _c("span", [_vm._v("N/A")])
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: {
            id: _vm.displayText,
            accordion: "apd-accordian",
            role: "tabpanel"
          }
        },
        [
          _c(
            "div",
            { staticClass: "mx-0 mx-md-3 my-3" },
            [
              _vm.orderedKPIs.length
                ? _vm._l(_vm.orderedKPIs, function(kpi) {
                    return _c(
                      "div",
                      { key: kpi },
                      [
                        _c(
                          "b-row",
                          { staticClass: "my-3" },
                          [
                            _c("b-col", { attrs: { cols: "0", xl: "1" } }),
                            _vm._v(" "),
                            _c("b-col", { attrs: { cols: "12", xl: "10" } }, [
                              _vm.apdByKPI[kpi].length
                                ? _c(
                                    "div",
                                    [
                                      _c("h4", [_vm._v(_vm._s(kpi))]),
                                      _vm._v(" "),
                                      _c("apd-report-kpi-section", {
                                        attrs: {
                                          "kpi-name": kpi,
                                          "kpi-data": _vm.apdByKPI[kpi]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : [
                    _c("b-row", { staticClass: "my-3" }, [
                      _c("div", { staticClass: "fs-5" }, [
                        _vm._v("No data publicly reported yet")
                      ])
                    ])
                  ]
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }