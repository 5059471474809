var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "my-3" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _vm.hasValueAndIsNotNA(_vm.apd.KPIDesc)
              ? _c("p", { staticClass: "my-0 small" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.apd.KPIDesc) +
                      "\n            "
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.apd.value || _vm.hasValueAndIsNotNA(_vm.apd.quantitativeDataQualifier)
        ? _c(
            "b-row",
            { staticClass: "my-3" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "p-3 sba-rounded-card" }, [
                  _c("h5", [_vm._v("Quantitative Data")]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.apd.value) + " " + _vm._s(_vm.apd.unit))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.hasValueAndIsNotNA(_vm.apd.quantitativeDataQualifier)
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.apd.quantitativeDataQualifier) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowAdditionalInfoCard(
        _vm.apd.qualitativeData,
        _vm.apd.cumulativeData
      )
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "p-3 sba-rounded-card" }, [
                  _c("h5", [_vm._v("Additional Information")]),
                  _vm._v(" "),
                  _vm.apd.qualitativeData
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.apd.qualitativeData) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.apd.cumulativeData
                    ? _c("p", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.apd.cumulativeData) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "my-1" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("strong", { staticClass: "small" }, [
              _vm._v("\n                Source:\n                "),
              _c(
                "a",
                { attrs: { href: _vm.apd.dataSource, target: "_blank" } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.apd.dataSourceType) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.apd.dataSourceInfo
              ? _c("p", { staticClass: "px-5" }, [
                  _c("i", [_vm._v(_vm._s(_vm.apd.dataSourceInfo))])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }