var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "span",
        {
          staticClass: "black symbology",
          attrs: {
            id: _vm.lifecycleStage + "_hover",
            alt: _vm.relevantSymbol.desc
          }
        },
        [
          _c("font-awesome-icon", {
            staticStyle: { width: "2em", height: "2em" },
            attrs: { icon: "fa-solid " + _vm.relevantSymbol.name }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.symbol == "cross"
        ? _c(
            "b-popover",
            {
              attrs: {
                target: _vm.lifecycleStage + "_hover",
                alt: _vm.relevantSymbol.desc,
                placement: "left",
                triggers: "hover focus"
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.relevantSymbol.desc) + "\n    ")]
          )
        : _c(
            "b-popover",
            {
              attrs: {
                target: _vm.lifecycleStage + "_hover",
                alt: _vm.relevantSymbol.desc,
                placement: "left",
                triggers: "hover focus"
              }
            },
            [
              _c("p", [_vm._v("Applicable lifecycle actions:")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.lifecycleActions, function(action) {
                  return _c("li", {
                    key: action,
                    staticClass: "mb-1",
                    domProps: { textContent: _vm._s(action) }
                  })
                }),
                0
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }