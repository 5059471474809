var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        staticClass: "scroll-link d-flex align-items-center",
        on: { click: _vm.scroll }
      },
      [
        _c("font-awesome-icon", {
          staticStyle: { width: "2em", height: "2em" },
          attrs: { icon: "fa-solid fa-circle-chevron-down" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "px-2" }, [_vm._v("Annual Performance Data")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }