<template>
    <div class="mx-1">
        <span
            :id="lifecycleStage + '_hover'"
            :alt="relevantSymbol.desc"
            class="black symbology"
        >
            <font-awesome-icon
                :icon="'fa-solid ' + relevantSymbol.name"
                style="width: 2em; height: 2em;"
            />
        </span>

        <b-popover
            v-if="symbol == 'cross'"
            :target="lifecycleStage + '_hover'"
            :alt="relevantSymbol.desc"
            placement="left"
            triggers="hover focus"
        >
            {{ relevantSymbol.desc }}
        </b-popover>
        <b-popover
            v-else
            :target="lifecycleStage + '_hover'"
            :alt="relevantSymbol.desc"
            placement="left"
            triggers="hover focus"
        >
            <p>Applicable lifecycle actions:</p>
            <ul>
                <li
                    v-for="action in lifecycleActions"
                    :key="action"
                    class="mb-1"
                    v-text="action"
                />
            </ul>
        </b-popover>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string } from 'vue-types';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
    faSquareCheck,
    faSquareXmark,
} from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faSquareCheck, faSquareXmark);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

export default Vue.component('LifecycleSymbology', {
    props: {
        lifecycleStage: string(),
        lifecycleStageDisplay: string(),
    },
    data() {
        return {
            symbolDefinition: {
                tick: {
                    name: 'fa-square-check',
                },
                cross: {
                    desc:
                        'This initiative does not operate in the ' +
                        this.lifecycleStageDisplay +
                        ' lifecycle stage',
                    name: 'fa-square-xmark',
                },
            },
            lifecycleActionDefinition: {
                // eslint-disable-next-line camelcase
                lifecycle_is_production: [
                    ['better_material_choices', 'Better material choices'],
                    ['better_product_design', 'Better product design'],
                    [
                        'responsible_supply_chain_practices',
                        'Responsible supply chain practices',
                    ],
                    [
                        'efficient_resource_use_in_manufacturing',
                        'Efficient resource use in manufacturing',
                    ],
                ],
                // eslint-disable-next-line camelcase
                lifecycle_is_consumption: [
                    [
                        'consumer_information_promoting_better_product_use',
                        'Consumer information promoting better product use',
                    ],
                    ['circular_business_models', 'Circular business models'],
                    [
                        'promoting_higher_quality_products',
                        'Promoting higher quality products',
                    ],
                    [
                        'product_trade_in_and_or_repair_services',
                        'Product-trade-in and/or repair services',
                    ],
                ],
                // eslint-disable-next-line camelcase
                lifecycle_is_postconsumption: [
                    [
                        'providing_take_back_services',
                        'Providing take-back services, improving access and convenience',
                    ],
                    [
                        'better_logistics_solutions',
                        'Better logistics solutions',
                    ],
                    [
                        'material_recovery',
                        'Material recovery (recycling and reprocessing)',
                    ],
                    ['new_end_markets', 'New end-markets'],
                ],
            },
        };
    },
    computed: {
        symbol() {
            return this.initiativeData[this.lifecycleStage] ? 'tick' : 'cross';
        },
        relevantSymbol() {
            return this.symbolDefinition[this.symbol];
        },
        lifecycleActions() {
            return this.lifecycleActionDefinition[this.lifecycleStage]
                .filter(([actionKeyName]) => this.initiativeData[actionKeyName])
                .map(([_, displayText]) => displayText); //eslint-disable-line no-unused-vars
        },
    },
    created() {
        this.initiativeData = JSON.parse(
            document.getElementById('initiative-data').textContent,
        );
    },
});
</script>
