var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sortedInitatives.length
        ? _c(
            "b-list-group",
            {
              staticClass: "overflow-auto mt-1 pe-2",
              staticStyle: { height: "400px" }
            },
            _vm._l(_vm.sortedInitatives, function(initiative) {
              return _c(
                "b-list-group-item",
                {
                  key: initiative.initiative_name,
                  staticClass: "initiative-search-results",
                  attrs: { href: "/initiatives/" + initiative.id }
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "9", lg: "8", xl: "9" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    order: "1",
                                    "order-md": "2",
                                    cols: "10",
                                    lg: "9",
                                    xl: "10"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "py-2" }, [
                                    _c("h6", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(initiative.initiative_name) +
                                          "\n                                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "small fw-normal" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              initiative.organisation__name
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    order: "2",
                                    "order-md": "1",
                                    cols: "2",
                                    lg: "3",
                                    xl: "2"
                                  }
                                },
                                [
                                  initiative.accreditation_status ==
                                  "accredited"
                                    ? _c("initiative-accredited-symbology", {
                                        attrs: {
                                          "image-type": "small",
                                          "show-tooltip": ""
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "pt-3 pt-md-0",
                          attrs: { cols: "12", md: "3", lg: "4", xl: "3" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("initiative-symbology", {
                                attrs: {
                                  "symbol-type": initiative.geographic_scope,
                                  "show-tooltip": ""
                                }
                              }),
                              _vm._v(" "),
                              _c("initiative-symbology", {
                                attrs: {
                                  "symbol-type": initiative.type_of_initiative,
                                  "show-tooltip": ""
                                }
                              }),
                              _vm._v(" "),
                              _c("initiative-symbology", {
                                attrs: {
                                  "symbol-type": initiative.development_stage,
                                  "show-tooltip": ""
                                }
                              }),
                              _vm._v(" "),
                              _c("lifecycle-summary-symbology", {
                                attrs: {
                                  production:
                                    initiative.lifecycle_is_production,
                                  consumption:
                                    initiative.lifecycle_is_consumption,
                                  postconsumption:
                                    initiative.lifecycle_is_postconsumption,
                                  "show-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _c(
            "b-alert",
            {
              staticStyle: { "margin-top": "6px" },
              attrs: { show: "", variant: "warning" }
            },
            [_vm._v("\n        No results available.\n    ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }