<template>
    <b-container class="bg-white">
        <b-row align-h="between" class="my-2">
            <b-col cols="5">
                <a href="/">
                    <img
                        class="ms-md-3 img-fluid header-logo"
                        src="/static/assets/img/logos/PSCOE.png"
                        alt="Product Stewardship Center of Excellence Logo"
                    />
                </a>
            </b-col>
            <b-col cols="5" class="d-flex justify-content-end">
                <a href="https://www.dcceew.gov.au/" target="_blank">
                    <img
                        class="me-3 img-fluid header-logo"
                        src="/static/assets/img/logos/DCCEEW.png"
                        alt="Department of Climate Change, Energy, the Environment and Water"
                    />
                </a>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';

export default Vue.component('HomeHeader', {});
</script>
