var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.filterChoices.length
        ? _c(
            "b-row",
            { attrs: { "align-v": "stretch", "align-h": "between" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "flex-grow-1",
                  attrs: { cols: "12", xl: "auto" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "filter-search",
                    attrs: {
                      placeholder:
                        "Search by product, material, initiative or organisation name"
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "mt-2 mt-xl-0",
                  attrs: { cols: "12", xl: "auto" }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex" },
                        [
                          _c("filter-dropdown", {
                            attrs: { "filter-choices": _vm.filterChoices },
                            on: { "update:filters": _vm.updateFilters }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "filter-button",
                              attrs: {
                                dropdown: "",
                                right: "",
                                text: "Sort by"
                              }
                            },
                            [
                              _c("b-list-group", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "list-group-item d-flex gap-2"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sortBy,
                                          expression: "sortBy"
                                        }
                                      ],
                                      staticClass:
                                        "form-check-input flex-shrink-0",
                                      attrs: {
                                        id: "sortAsc",
                                        value: "sortAsc",
                                        type: "radio"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.sortBy, "sortAsc")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.sortBy = "sortAsc"
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "\n                                    A\n                                    "
                                        ),
                                        _c("font-awesome-icon", {
                                          staticClass: "mx-1",
                                          attrs: {
                                            icon: "fa-solid fa-arrow-right-long"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    Z\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "list-group-item d-flex gap-2"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.sortBy,
                                          expression: "sortBy"
                                        }
                                      ],
                                      staticClass:
                                        "form-check-input flex-shrink-0",
                                      attrs: {
                                        id: "sortDesc",
                                        value: "sortDesc",
                                        type: "radio"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.sortBy, "sortDesc")
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.sortBy = "sortDesc"
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "\n                                    Z\n                                    "
                                        ),
                                        _c("font-awesome-icon", {
                                          staticClass: "mx-1",
                                          attrs: {
                                            icon: "fa-solid fa-arrow-right-long"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    A\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-row", { staticClass: "mt-2 mb-1 px-3" }, [
                    _c("div", { staticClass: "d-flex small disclaimer-card" }, [
                      _c("i", [_c("strong", [_vm._v("i")])]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Hover over icons for more info")])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("initiative-results-display", {
            attrs: {
              initiatives: _vm.filterAdvancedInitiatives,
              "sort-by": _vm.sortBy
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }