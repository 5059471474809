<template>
    <div>
        <b-button v-b-modal.modal-1 class="filter-button me-2">
            Filter by
            <b-badge pill v-text="count" />
        </b-button>

        <b-modal id="modal-1">
            <template #modal-header="{ close }">
                <h5 class="modal-title">Refine and filter</h5>
                <b-button class="btn button" @click="close">
                    x
                </b-button>
            </template>
            <b-dropdown
                v-for="filterType in filterChoices"
                :key="filterType.id"
                dropdown
                :class="{
                    'bg-white': !checked[filterType.id].length,
                    show: checked[filterType.id].length,
                    'me-1': true,
                    'mb-1': true,
                }"
                size="sm"
                style="border: 1px solid rgba(0, 0, 0, 0.125);"
            >
                <template v-if="checked[filterType.id].length" #button-content>
                    {{ filterType.name }} ({{ checked[filterType.id].length }})
                </template>
                <template v-else #button-content>
                    {{ filterType.name }}
                </template>
                <b-list-group
                    v-for="(filterTypeValue, index) in filterType.attributes
                        .values"
                    :key="filterTypeValue"
                >
                    <label class="list-group-item d-flex gap-2 fs-6">
                        <input
                            :id="filterTypeValue"
                            v-model="checked[filterType.id]"
                            :value="filterTypeValue"
                            class="form-check-input flex-shrink-0"
                            type="checkbox"
                            @change="updateFilters(filterType.id)"
                        />
                        <initiative-accredited-symbology
                            v-if="filterTypeValue == 'accredited'"
                            image-type="small"
                            size="2em"
                            show-tooltip
                        />
                        <initiative-symbology
                            v-else-if="
                                !['ministerList', 'regulatoryModel'].includes(
                                    filterType.id,
                                )
                            "
                            :symbol-type="filterTypeValue"
                            show-tooltip
                        />
                        <span>
                            {{ filterType.attributes.labels[index] }}
                        </span>
                    </label>
                </b-list-group>
            </b-dropdown>
            <template #modal-footer>
                <button
                    class="btn btn-primary btn-sm mt-3"
                    @click="clearFilters"
                >
                    Clear all filters
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { arrayOf, shape, string } from 'vue-types';
import _isEmpty from 'lodash/isEmpty';
import InitiativeSymbology from './InitiativeSymbology.vue';

const DROPDOWN_FILTER_STORAGE_KEY = 'savedFilters';

export default Vue.component('FilterDropdown', {
    components: {
        InitiativeSymbology,
    },
    props: {
        filterChoices: arrayOf(
            shape({
                name: string(),
                id: string(),
                attributes: {
                    labels: arrayOf(string()),
                    values: arrayOf(string()),
                },
            }),
        ),
    },
    data() {
        return {
            //still needs to store the checked values as the component displays the number of checked filters
            checked: {
                typeOfInitiative: [],
                developmentStage: [],
                lifecycleStage: [],
                accreditationStatus: [],
                geographicScope: [],
                ministerList: [],
                regulatoryModel: [],
            },
        };
    },
    computed: {
        count() {
            return Object.keys(this.checked).filter(
                selectedOption => this.checked[selectedOption].length > 0,
            ).length;
        },
    },
    watch: {
        checked: {
            handler(val) {
                localStorage.setItem(
                    DROPDOWN_FILTER_STORAGE_KEY,
                    JSON.stringify(val),
                );
            },
            deep: true,
        },
    },
    mounted() {
        // Get filters params from local storage.
        const savedFilters = JSON.parse(
            localStorage.getItem(DROPDOWN_FILTER_STORAGE_KEY),
        );
        if (!_isEmpty(savedFilters)) {
            // Use the spread operator to ensure this.checked and savedFilters are merged.
            // savedFilters is not guaranteed to have the same variables as this.checked
            // (eg filters maybe be added or removed)
            this.checked = { ...this.checked, ...savedFilters };
            for (const key in this.checked) {
                this.updateFilters(key);
            }
        }
    },
    methods: {
        clearFilters() {
            this.checked = {
                typeOfInitiative: [],
                developmentStage: [],
                lifecycleStage: [],
                accreditationStatus: [],
                geographicScope: [],
                ministerList: [],
                regulatoryModel: [],
            };
            for (const key in this.checked) {
                this.updateFilters(key);
            }
        },
        updateFilters(filter) {
            this.$emit('update:filters', filter, this.checked[filter]);
        },
    },
});
</script>
