<template>
    <div class="d-flex align-items-center">
        <span
            v-if="relevantSymbol.fontAwesome"
            :id="symbolId"
            :alt="relevantSymbol.type + ' ' + relevantSymbol.desc"
            class="black me-2 symbology"
        >
            <font-awesome-icon
                :icon="'fa-solid ' + relevantSymbol.name"
                style="width: 2em; height: 2em;"
            />
        </span>
        <b-img
            v-else
            :id="symbolId"
            :src="baseSrc + relevantSymbol.src + '.png'"
            :alt="relevantSymbol.type + ' ' + relevantSymbol.desc"
            class="me-2 symbology"
            style="width: 2em; height: 2em;"
        />
        <b-popover
            v-if="showTooltip"
            :target="symbolId"
            triggers="hover"
            placement="right"
            :title="relevantSymbol.type"
        >
            <p
                v-for="line in splitDesc(relevantSymbol.desc)"
                :key="line"
                class="m-0"
            >
                {{ line }}
            </p>
        </b-popover>
        <div v-if="showText">
            <p
                v-for="line in splitDesc(relevantSymbol.desc)"
                :key="line"
                class="m-0"
            >
                {{ line }}
            </p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string } from 'vue-types';
import _uniqueId from 'lodash/uniqueId';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
    faUsers,
    faUser,
    faClipboardList,
    faGear,
    faCircleStop,
} from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faUsers, faUser, faClipboardList, faGear, faCircleStop);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

export default Vue.component('InitiativeSymbology', {
    props: {
        symbolType: string(),
        showTooltip: Boolean,
        showText: Boolean,
    },
    data() {
        return {
            baseSrc: '/static/assets/img/initiative-symbology/',
            symbolArray: [
                {
                    id: 'nat',
                    desc: 'National',
                    type: 'Location',
                    src: 'nat',
                },
                {
                    id: 'vic',
                    desc: 'Victoria',
                    type: 'Location',
                    src: 'vic',
                },
                {
                    id: 'nsw',
                    desc: 'New South Wales',
                    type: 'Location',
                    src: 'nsw',
                },
                {
                    id: 'nt',
                    desc: 'Northern Territory',
                    type: 'Location',
                    src: 'nt',
                },
                {
                    id: 'sa',
                    desc: 'South Australia',
                    type: 'Location',
                    src: 'sa',
                },
                {
                    id: 'tas',
                    desc: 'Tasmania',
                    type: 'Location',
                    src: 'tas',
                },
                {
                    id: 'act',
                    desc: 'Australian Capital Territory',
                    type: 'Location',
                    src: 'act',
                },
                {
                    id: 'wa',
                    desc: 'Western Australia',
                    type: 'Location',
                    src: 'wa',
                },
                {
                    id: 'qld',
                    desc: 'Queensland',
                    type: 'Location',
                    src: 'qld',
                },
                {
                    id: 'planning',
                    desc: 'Initiative not yet operational',
                    type: 'Development stage',
                    fontAwesome: true,
                    name: 'fa-clipboard-list',
                },
                {
                    id: 'operational',
                    desc: 'Initiative currently operating',
                    type: 'Development stage',
                    fontAwesome: true,
                    name: 'fa-gear',
                },
                {
                    id: 'not_operational',
                    desc: 'Initiative has been discontinued',
                    type: 'Development stage',
                    fontAwesome: true,
                    name: 'fa-circle-stop',
                },
                {
                    id: 'collective',
                    desc: 'Collective scheme',
                    type: 'Initiative type',
                    fontAwesome: true,
                    name: 'fa-users',
                },
                {
                    id: 'individual',
                    desc: 'Individual business',
                    type: 'Initiative type',
                    fontAwesome: true,
                    name: 'fa-user',
                },
                {
                    id: 'lifecycle_is_production',
                    desc: 'Production',
                    type: 'Lifecycle stage',
                    src: 'production',
                },
                {
                    id: 'lifecycle_is_consumption',
                    desc: 'Consumption',
                    type: 'Lifecycle stage',
                    src: 'consumption',
                },
                {
                    id: 'lifecycle_is_postconsumption',
                    desc: 'Post-consumption',
                    type: 'Lifecycle stage',
                    src: 'post_consumption',
                },
                {
                    id: 'lifecycle_is_production_consumption',
                    desc: 'Production\nConsumption',
                    type: 'Lifecycle stage',
                    src: 'production_consumption',
                },
                {
                    id: 'lifecycle_is_production_postconsumption',
                    desc: 'Production\nPost-consumption',
                    type: 'Lifecycle stage',
                    src: 'production_post_consumption',
                },
                {
                    id: 'lifecycle_is_consumption_postconsumption',
                    desc: 'Consumption\nPost-consumption',
                    type: 'Lifecycle stage',
                    src: 'consumption_post_consumption',
                },
                {
                    id: 'lifecycle_is_production_consumption_postconsumption',
                    desc: 'Production\nConsumption\nPost-consumption',
                    type: 'Lifecycle stage',
                    src: 'production_consumption_post_consumption',
                },
            ],
        };
    },
    computed: {
        relevantSymbol() {
            return this.symbolArray.find(({ id }) => id === this.symbolType);
        },
    },
    created() {
        this.symbolId = _uniqueId();
    },
    methods: {
        splitDesc(description) {
            return description.split('\n');
        },
    },
});
</script>
