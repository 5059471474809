var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-img", {
        style: "width:" + _vm.size,
        attrs: {
          id: _vm.symbolId,
          src: _vm.baseSrc + _vm.imageType + ".png",
          alt: _vm.accredited.type + " " + _vm.accredited.desc
        }
      }),
      _vm._v(" "),
      _vm.showTooltip
        ? _c(
            "b-popover",
            {
              attrs: {
                target: _vm.symbolId,
                triggers: "hover",
                placement: "right",
                title: _vm.accredited.type
              }
            },
            [
              _c("p", { staticClass: "m-0" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.accredited.desc) + "\n        "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showText
        ? _c("div", [
            _c("p", { staticClass: "m-0" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.accredited.desc) + "\n        "
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }