var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "statistic-icon", attrs: { alt: _vm.prettyIconName } },
    [
      _c("font-awesome-icon", {
        staticStyle: { width: "2.5em", height: "2.5em" },
        attrs: { icon: "fa-solid " + _vm.icon }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }