var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "footer" } },
    [
      _c(
        "b-row",
        {
          staticClass: "bg-white py-3",
          attrs: { "align-v": "center", "align-h": "end" }
        },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-md-end align-items-center",
              attrs: { cols: "12", md: "4", lg: "6", xl: "1", "order-xl": "1" }
            },
            [
              _c("div", { staticClass: "mx-4 mb-2 mx-md-5 mb-md-0" }, [
                _c(
                  "a",
                  { staticClass: "text-decoration-none", attrs: { href: "/" } },
                  [_vm._v("\n                    Home\n                ")]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "text-decoration-none",
                    attrs: { href: "/login" }
                  },
                  [_vm._v("\n                    Login\n                ")]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "text-decoration-none",
                    attrs: { href: "mailto:info@stewardshipexcellence.com.au" }
                  },
                  [_vm._v("\n                    Contact\n                ")]
                )
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "py-md-5 align-self-center sba-footer-vertical-line"
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "logos d-flex",
              attrs: { cols: "12", md: "8", lg: "6", xl: "4", "order-xl": "2" }
            },
            [
              _c(
                "a",
                {
                  attrs: { href: "https://www.uts.edu.au/", target: "_blank" }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid footer-logo",
                    attrs: {
                      src: "/static/assets/img/logos/UTS.png",
                      alt: "University of Technology Sydney Logo"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://stewardshipexcellence.com.au",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid footer-logo",
                    attrs: {
                      src: "/static/assets/img/logos/PSCOE.png",
                      alt: "Product Stewardship Center of Excellence Logo"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.dcceew.gov.au/",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid footer-logo",
                    attrs: {
                      src: "/static/assets/img/logos/DCCEEW_footer.png",
                      alt:
                        "Department of Climate Change, Energy, the Environment and Water"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "mt-3 mt-xl-0",
              attrs: { cols: "12", xl: "7", "order-xl": "0" }
            },
            [
              _c("div", { staticClass: "mx-3" }, [
                _c("p", { staticClass: "small m-0 fw-light fst-italic" }, [
                  _vm._v(
                    "\n                    The content of this Gateway is aggregated from publicly\n                    available sources. The project team has used all due\n                    care to ensure the data is accurate and collective\n                    schemes and individual initiatives have been given an\n                    opportunity to review content. The presentation of data\n                    does not represent an endorsement or independent\n                    verification of the data, and the project team and the\n                    Commonwealth Department of Climate Change, Energy, the\n                    Environment and Water are not responsible for\n                    inaccuracies, errors, or misinterpretation.\n                "
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("b-row", { staticClass: "sba-footer-space py-3" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }