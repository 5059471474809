<template>
    <div id="footer">
        <!--
            Footer is a b-row with 2 b-cols: stewardship logo (left) and login AND additional logos (right)
            Column sizes are responsive
                    left    right
            xs      12      12      (one row each)
            sm      12      12      (one row each)
            md      4       8
            lg      6       6
            xl      7       5
        -->
        <b-row align-v="center" align-h="end" class="bg-white py-3">
            <!-- Stewardship logo and login are right aligned when screen size md or larger -->
            <b-col
                cols="12"
                md="4"
                lg="6"
                xl="1"
                order-xl="1"
                class="d-flex justify-content-md-end align-items-center"
            >
                <div class="mx-4 mb-2 mx-md-5 mb-md-0">
                    <a class="text-decoration-none" href="/">
                        Home
                    </a>
                    <br />
                    <a class="text-decoration-none" href="/login">
                        Login
                    </a>
                    <br />
                    <a
                        class="text-decoration-none"
                        href="mailto:info@stewardshipexcellence.com.au"
                    >
                        Contact
                    </a>
                </div>
                <div
                    class="py-md-5 align-self-center sba-footer-vertical-line"
                />
            </b-col>
            <b-col
                cols="12"
                md="8"
                lg="6"
                xl="4"
                order-xl="2"
                class="logos d-flex"
            >
                <a href="https://www.uts.edu.au/" target="_blank">
                    <img
                        class="img-fluid footer-logo"
                        src="/static/assets/img/logos/UTS.png"
                        alt="University of Technology Sydney Logo"
                    />
                </a>
                <a href="https://stewardshipexcellence.com.au" target="_blank">
                    <img
                        class="img-fluid footer-logo"
                        src="/static/assets/img/logos/PSCOE.png"
                        alt="Product Stewardship Center of Excellence Logo"
                    />
                </a>
                <a href="https://www.dcceew.gov.au/" target="_blank">
                    <img
                        class="img-fluid footer-logo"
                        src="/static/assets/img/logos/DCCEEW_footer.png"
                        alt="Department of Climate Change, Energy, the Environment and Water"
                    />
                </a>
            </b-col>
            <b-col cols="12" xl="7" class="mt-3 mt-xl-0" order-xl="0">
                <div class="mx-3">
                    <p class="small m-0 fw-light fst-italic">
                        The content of this Gateway is aggregated from publicly
                        available sources. The project team has used all due
                        care to ensure the data is accurate and collective
                        schemes and individual initiatives have been given an
                        opportunity to review content. The presentation of data
                        does not represent an endorsement or independent
                        verification of the data, and the project team and the
                        Commonwealth Department of Climate Change, Energy, the
                        Environment and Water are not responsible for
                        inaccuracies, errors, or misinterpretation.
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row class="sba-footer-space py-3" />
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';

export default Vue.component('HomeFooter', {});
</script>
