<script>
import Vue from 'vue/dist/vue.esm.js';

let SiteWrapper = Vue.component('SiteWrapper', {
    props: {},
    data() {
        return {};
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {},
    },
});

export default SiteWrapper;
</script>
