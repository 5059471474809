<template>
    <div>
        <b-img
            :id="symbolId"
            :src="baseSrc + imageType + '.png'"
            :alt="accredited.type + ' ' + accredited.desc"
            :style="'width:' + size"
        />
        <b-popover
            v-if="showTooltip"
            :target="symbolId"
            triggers="hover"
            placement="right"
            :title="accredited.type"
        >
            <p class="m-0">
                {{ accredited.desc }}
            </p>
        </b-popover>
        <div v-if="showText">
            <p class="m-0">
                {{ accredited.desc }}
            </p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import _uniqueId from 'lodash/uniqueId';

export default Vue.component('InitiativeAccreditedSymbology', {
    props: {
        imageType: { type: String, default: 'small' },
        showTooltip: Boolean,
        showText: Boolean,
        size: { type: String, default: '4em' },
    },
    data() {
        return {
            baseSrc: '/static/assets/img/initiative-symbology/accredited_',
            accredited: {
                id: 'accredited',
                desc: 'Accredited',
                type: 'Accreditation status',
            },
        };
    },
    created() {
        this.symbolId = _uniqueId();
    },
});
</script>
