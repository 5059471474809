<template>
    <div>
        <div class="d-none d-md-block my-5">
            <initiative-symbology
                :symbol-type="geographicScope"
                show-text
                class="my-3"
            />
            <initiative-symbology
                :symbol-type="typeOfInitiative"
                show-text
                class="my-3"
            />
            <initiative-symbology
                :symbol-type="developmentStage"
                show-text
                class="my-3"
            />
            <lifecycle-summary-symbology
                :production="lifecycleIsProduction"
                :consumption="lifecycleIsConsumption"
                :postconsumption="lifecycleIsPostconsumption"
                show-text
                symbology-class="my-3"
            />
        </div>
        <div class="d-flex d-md-none my-1">
            <initiative-symbology
                :symbol-type="geographicScope"
                show-tooltip
                class="my-2"
            />
            <initiative-symbology
                :symbol-type="typeOfInitiative"
                show-tooltip
                class="my-2"
            />
            <initiative-symbology
                :symbol-type="developmentStage"
                show-tooltip
                class="my-2"
            />
            <lifecycle-summary-symbology
                :production="lifecycleIsProduction"
                :consumption="lifecycleIsConsumption"
                :postconsumption="lifecycleIsPostconsumption"
                show-tooltip
                symbology-class="my-2"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string, bool } from 'vue-types';

export default Vue.component('InitiativeSymbologyPanel', {
    props: {
        geographicScope: string(),
        typeOfInitiative: string(),
        accreditationStatus: string(),
        developmentStage: string(),
        lifecycleIsProduction: bool(),
        lifecycleIsConsumption: bool(),
        lifecycleIsPostconsumption: bool(),
    },
});
</script>
