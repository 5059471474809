var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-none d-md-block my-5" },
      [
        _c("initiative-symbology", {
          staticClass: "my-3",
          attrs: { "symbol-type": _vm.geographicScope, "show-text": "" }
        }),
        _vm._v(" "),
        _c("initiative-symbology", {
          staticClass: "my-3",
          attrs: { "symbol-type": _vm.typeOfInitiative, "show-text": "" }
        }),
        _vm._v(" "),
        _c("initiative-symbology", {
          staticClass: "my-3",
          attrs: { "symbol-type": _vm.developmentStage, "show-text": "" }
        }),
        _vm._v(" "),
        _c("lifecycle-summary-symbology", {
          attrs: {
            production: _vm.lifecycleIsProduction,
            consumption: _vm.lifecycleIsConsumption,
            postconsumption: _vm.lifecycleIsPostconsumption,
            "show-text": "",
            "symbology-class": "my-3"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex d-md-none my-1" },
      [
        _c("initiative-symbology", {
          staticClass: "my-2",
          attrs: { "symbol-type": _vm.geographicScope, "show-tooltip": "" }
        }),
        _vm._v(" "),
        _c("initiative-symbology", {
          staticClass: "my-2",
          attrs: { "symbol-type": _vm.typeOfInitiative, "show-tooltip": "" }
        }),
        _vm._v(" "),
        _c("initiative-symbology", {
          staticClass: "my-2",
          attrs: { "symbol-type": _vm.developmentStage, "show-tooltip": "" }
        }),
        _vm._v(" "),
        _c("lifecycle-summary-symbology", {
          attrs: {
            production: _vm.lifecycleIsProduction,
            consumption: _vm.lifecycleIsConsumption,
            postconsumption: _vm.lifecycleIsPostconsumption,
            "show-tooltip": "",
            "symbology-class": "my-2"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }