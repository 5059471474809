<template>
    <div>
        <template v-if="kpiYearsSorted.length > 1">
            <label :for="selectId">
                <strong class="small">Reporting Year:</strong>
            </label>
            <select :id="selectId" v-model="selectedYear">
                <option
                    v-for="year in kpiYearsSorted"
                    :key="year"
                    :value="year"
                >
                    {{ year }}
                </option>
            </select>
        </template>
        <div v-if="selectedYear" :key="selectedYear">
            <div class="mt-3 mb-4 indent">
                <h5 style="border-bottom: 1px solid #333">
                    {{ selectedYear }} Data
                </h5>
                <apd-report :apd="selectedKpiYearData" />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import _uniqueId from 'lodash/uniqueId';

import { string, array } from 'vue-types';

export default Vue.component('ApdReportKpiSection', {
    props: {
        kpiName: string.isRequired,
        kpiData: array.isRequired,
    },
    data() {
        return {
            loading: true,
            selectedYear: null,
        };
    },
    computed: {
        kpiYearsSorted() {
            const years = this.kpiData.map(item => item.year);
            years.sort((a, b) => b - a);
            return years;
        },
        selectedKpiYearData() {
            if (!this.selectedYear) {
                return null;
            }
            return this.kpiData.find(item => item.year === this.selectedYear);
        },
    },
    created() {
        this.selectId = _uniqueId('year_selector');
    },
    mounted() {
        this.selectedYear = this.kpiYearsSorted[0];
    },
});
</script>

<style lang="scss" scoped>
.indent {
    margin-left: 30px;
}
</style>
