<template>
    <span :alt="prettyIconName" class="statistic-icon">
        <font-awesome-icon
            :icon="'fa-solid ' + icon"
            style="width: 2.5em; height: 2.5em;"
        />
    </span>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string } from 'vue-types';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
    faPenToSquare,
    faPeopleArrowsLeftRight,
    faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(faPenToSquare, faPeopleArrowsLeftRight, faClipboardCheck);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export default Vue.component('StatisticIconography', {
    props: {
        icon: string(),
    },
    computed: {
        prettyIconName() {
            return this.icon.replace('-', ' ') + ' icon';
        },
    },
});
</script>
