<template>
    <initiative-symbology
        :symbol-type="applicableLifecycles"
        :show-tooltip="showTooltip"
        :show-text="showText"
        :class="symbologyClass"
    />
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string, bool } from 'vue-types';
import InitiativeSymbology from './InitiativeSymbology.vue';

export default Vue.component('LifecycleSummarySymbology', {
    components: {
        InitiativeSymbology,
    },
    props: {
        production: bool(),
        consumption: bool(),
        postconsumption: bool(),
        showTooltip: Boolean,
        showText: Boolean,
        symbologyClass: string(),
    },
    computed: {
        applicableLifecycles() {
            let applicableLifecycles = [];
            if (this.production) {
                applicableLifecycles.push('production');
            }
            if (this.consumption) {
                applicableLifecycles.push('consumption');
            }
            if (this.postconsumption) {
                applicableLifecycles.push('postconsumption');
            }
            return 'lifecycle_is_' + applicableLifecycles.join('_');
        },
    },
});
</script>
