var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "bg-white" },
    [
      _c(
        "b-row",
        { staticClass: "my-2", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "5" } }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                staticClass: "ms-md-3 img-fluid header-logo",
                attrs: {
                  src: "/static/assets/img/logos/PSCOE.png",
                  alt: "Product Stewardship Center of Excellence Logo"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-end", attrs: { cols: "5" } },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.dcceew.gov.au/",
                    target: "_blank"
                  }
                },
                [
                  _c("img", {
                    staticClass: "me-3 img-fluid header-logo",
                    attrs: {
                      src: "/static/assets/img/logos/DCCEEW.png",
                      alt:
                        "Department of Climate Change, Energy, the Environment and Water"
                    }
                  })
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }