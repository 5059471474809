import Vue from 'vue/dist/vue.esm.js';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

import './font-awesome-loader';
import './vue_components/SiteWrapper.vue';
import './vue_components/HomeProductClasses.vue';
import './vue_components/HomeHeader.vue';
import './vue_components/HomeFooter.vue';
import './vue_components/InitiativeResultsDisplay.vue';
import './vue_components/InitiativeSymbology.vue';
import './vue_components/InitiativeAccreditedSymbology.vue';
import './vue_components/InitiativeSymbologyPanel.vue';
import './vue_components/LifecycleSymbology.vue';
import './vue_components/StatisticIconography.vue';
import './vue_components/InitiativeApdDisplay.vue';
import './vue_components/ScrollToApdSection.vue';
import './vue_components/ApdReport.vue';
import './vue_components/ApdReportKpiSection.vue';

new Vue({ el: '#vue-app' });
