<template>
    <b-row class="sba-white-card mb-3">
        <b-button v-b-toggle="displayText" block class="p-4 fs-3 text-start">
            <div class="d-flex justify-content-between">
                <b>{{ displayText }}</b>
                <b-badge pill>
                    <span v-if="orderedKPIs.length">
                        {{ orderedKPIs.length }}
                    </span>
                    <span v-else>N/A</span>
                </b-badge>
            </div>
        </b-button>
        <b-collapse :id="displayText" accordion="apd-accordian" role="tabpanel">
            <div class="mx-0 mx-md-3 my-3">
                <template v-if="orderedKPIs.length">
                    <div v-for="kpi in orderedKPIs" :key="kpi">
                        <b-row class="my-3">
                            <b-col cols="0" xl="1" />
                            <b-col cols="12" xl="10">
                                <div v-if="apdByKPI[kpi].length">
                                    <h4>{{ kpi }}</h4>
                                    <apd-report-kpi-section
                                        :kpi-name="kpi"
                                        :kpi-data="apdByKPI[kpi]"
                                    />
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </template>
                <template v-else>
                    <b-row class="my-3">
                        <div class="fs-5">No data publicly reported yet</div>
                    </b-row>
                </template>
            </div>
        </b-collapse>
    </b-row>
</template>

<script>
import Vue from 'vue/dist/vue.esm.js';
import { string } from 'vue-types';
import _groupBy from 'lodash/groupBy';

export default Vue.component('InitiativeApdDisplay', {
    props: {
        displayText: string(),
        apd: string(),
    },
    computed: {
        apdByKPI() {
            return _groupBy(this.allData, item => item.linkedKPI);
        },
        orderedKPIs() {
            return Object.keys(this.apdByKPI).sort();
        },
    },
    created() {
        this.allData = JSON.parse(
            document.getElementById(this.apd).textContent,
        );
    },
});
</script>
