var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.kpiYearsSorted.length > 1
        ? [
            _c("label", { attrs: { for: _vm.selectId } }, [
              _c("strong", { staticClass: "small" }, [
                _vm._v("Reporting Year:")
              ])
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedYear,
                    expression: "selectedYear"
                  }
                ],
                attrs: { id: _vm.selectId },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedYear = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.kpiYearsSorted, function(year) {
                return _c("option", { key: year, domProps: { value: year } }, [
                  _vm._v("\n                " + _vm._s(year) + "\n            ")
                ])
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedYear
        ? _c("div", { key: _vm.selectedYear }, [
            _c(
              "div",
              { staticClass: "mt-3 mb-4 indent" },
              [
                _c(
                  "h5",
                  { staticStyle: { "border-bottom": "1px solid #333" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.selectedYear) +
                        " Data\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("apd-report", { attrs: { apd: _vm.selectedKpiYearData } })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }